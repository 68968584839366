import React from 'react'
import classNames from 'classnames'
import { Case, Default, Switch } from 'react-if'
import { FireIcon } from '@/atoms/Icons/FireIcon'
import { FirstInitialProfileIcon } from '@/atoms/Icons/FirstInitialProfileIcon'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { colors } from '@/constants/colors'
import { Avatar } from '@/molecules/Avatar'
import { isValidUrl } from '@/services/validations'

interface WatchPartyAvatarProps {
  hasInvested?: boolean
  hasPaid?: boolean
  isSubscribed?: boolean
  isGuildMember?: boolean
  avatarUrl?: string | null
  name?: string
}

type AngelStatus = 'investor' | 'subscriber' | 'piffer' | 'free' | 'guild'

export const WatchPartyAvatar: React.FC<WatchPartyAvatarProps> = ({
  avatarUrl,
  hasInvested,
  isSubscribed,
  hasPaid,
  isGuildMember,
  name,
}) => {
  let angelStatus = 'free' as AngelStatus

  if (hasInvested) {
    angelStatus = 'investor'
  } else if (isSubscribed) {
    angelStatus = 'subscriber'
  } else if (hasPaid) {
    angelStatus = 'piffer'
  } else if (isGuildMember) {
    angelStatus = 'guild'
  }

  return (
    <div
      className={classNames('self-start relative rounded-full border-[3px]', {
        'border-transparent': angelStatus === 'free' || angelStatus === 'piffer' || angelStatus === 'guild',
        'border-copper': angelStatus === 'investor',
        'border-[#16B087]': angelStatus === 'subscriber',
      })}
    >
      <div className="rounded-full border border-black">
        <Switch>
          <Case condition={isValidUrl(avatarUrl || '')}>
            <Avatar src={avatarUrl} size={48} />
          </Case>
          <Default>
            <FirstInitialProfileIcon initial={name?.[0]} size={48} color1="gray-800" />
          </Default>
        </Switch>
        {angelStatus !== 'free' && (
          <div
            className={classNames('rounded-full h-4 w-4 absolute -right-1 bottom-0 flex items-center align-middle', {
              'bg-copper': angelStatus === 'investor',
              'bg-[#16B087]': angelStatus === 'subscriber' || angelStatus === 'piffer',
            })}
          >
            <Switch>
              <Case condition={angelStatus === 'investor'}>
                <FireIcon className="m-auto" />
              </Case>
              <Case condition={angelStatus === 'subscriber' || angelStatus === 'piffer'}>
                <PayItForwardIcon className="m-auto" size={8} color="white" />
              </Case>
            </Switch>
          </div>
        )}
        {angelStatus === 'guild' && (
          <div
            className="absolute -bottom-1 -right-1 flex h-5 w-5 items-center justify-center rounded-full border border-black"
            style={{ backgroundColor: colors['core-gray-800'] }}
          >
            <GuildIcon size={12} color1="cool-02" color2="core-gray-800" fill={true} />
          </div>
        )}
      </div>
    </div>
  )
}
