import React from 'react'
import classNames from 'classnames'
import { Case, Default, Switch } from 'react-if'
import { AppMessage } from '@/molecules/ChatbotMessages/AppMessage'
import { ClaimMessage } from '@/molecules/ChatbotMessages/ClaimMessage'
import { GuildMessage } from '@/molecules/ChatbotMessages/GuildMessage'
import { PifMessage } from '@/molecules/ChatbotMessages/PifMessage'
import { TicketIntentMessage } from '@/molecules/ChatbotMessages/TicketIntentMessage'
import { WatchPartyMessage } from '@/molecules/WatchPartyMessage'
import {
  ChatbotMessagePif,
  ChatFeedMessage,
  ChatMessage,
  isChatbotMessagePif,
  isChatMessage,
} from '@/services/WatchPartyChatService'
import {
  ChatbotMessageClaim,
  ChatbotMessageTicketIntent,
  ChatbotMessageGuildSubscription,
} from '@/services/WatchPartyChatService/types'
import {
  isChatbotMessageClaim,
  isChatbotMessageTicketIntent,
  isChatbotMessageGuildSubscription,
} from '@/services/WatchPartyChatService/utils'
import { TheatricalReleaseObject } from '@/types/codegen-federation'

interface ChatbotMessageProps {
  message: ChatFeedMessage
  isOneLineMessages?: boolean
  className?: string
  initialMessageClassName?: string
  theatricalRelease?: TheatricalReleaseObject | null
}

export const ChatbotMessage: React.FC<ChatbotMessageProps> = ({
  message,
  isOneLineMessages,
  className,
  initialMessageClassName,
  theatricalRelease,
}) => {
  return (
    <Switch>
      <Case condition={isChatbotMessageGuildSubscription(message)}>
        <GuildMessage
          name={(message as ChatbotMessageGuildSubscription)?.user?.first_name}
          isYearSubscription={(message as ChatbotMessageGuildSubscription).is_year_subscription}
        />
      </Case>
      <Case condition={isChatMessage(message)}>
        <WatchPartyMessage message={message as ChatMessage} className={className} />
      </Case>
      <Case condition={isChatbotMessagePif(message)}>
        <PifMessage
          message={message.message}
          className={className}
          first_name={(message as ChatbotMessagePif)?.user?.first_name}
          quantity={(message as ChatbotMessagePif)?.quantity}
        />
      </Case>
      <Case condition={isChatbotMessageClaim(message)}>
        <ClaimMessage message={message as ChatbotMessageClaim} className={className} />
      </Case>
      <Case condition={isChatbotMessageTicketIntent(message)}>
        <TicketIntentMessage
          message={message as ChatbotMessageTicketIntent}
          theatricalRelease={theatricalRelease}
          className={classNames(className, {
            'whitespace-nowrap overflow-x-hidden text-ellipsis !text-[10px]': isOneLineMessages,
          })}
        />
      </Case>
      <Default>
        <AppMessage
          {...message}
          className={classNames(className, {
            '!text-[10px]': isOneLineMessages,
          })}
          displayNameClassName={isOneLineMessages ? 'hidden' : initialMessageClassName}
          avatarSize={24}
        />
      </Default>
    </Switch>
  )
}
