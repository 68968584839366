import React from 'react'

export enum SocketChannelTopic {
  chatbot_message = 'chatbot_message',
  delete_message = 'delete_message',
  shout = 'shout',
  update_cta = 'update_cta',
  user_count_update = 'user_count_update',
}

export enum ChatbotMessageType {
  app = 'app',
  merch = 'merch',
  pif = 'pif',
  claim = 'claim',
  ticket = 'ticket',
  guild = 'guild_subscription',
}

export interface ChatbotMessage {
  message: string | React.ReactNode
  publicId: string
  ts: Date
  type: ChatbotMessageType
}

export interface ChatbotUser {
  country?: string
  first_name: string
  state?: string
  avatarUrl?: string
}

export interface ChatbotMessagePif extends ChatbotMessage {
  quantity: number
  type: ChatbotMessageType.pif
  user: ChatbotUser
  version: number
}

export interface ChatbotMessageClaim extends ChatbotMessage {
  type: ChatbotMessageType.claim
  user: ChatbotUser
  offer_type?: 'standard_scene' | 'golden_scene'
  offer_name?: string
  offer_id?: string
  recently_eligible?: boolean
  eligible_at?: string
  eligible_action: 'pif' | 'pif_subscription' | 'investment' | 'ticket_purchase'
}

export interface ChatbotMessageTicketIntent extends ChatbotMessage {
  type: ChatbotMessageType.ticket
  user: ChatbotUser
  theatrical_name: string
  venue_name: string
  venue_state: string
  venue_country_code: string
  venue_city: string
  latitude: number
  longitude: number
}

export interface ChatbotMessageGuildSubscription extends ChatbotMessage {
  type: ChatbotMessageType.guild
  user: ChatbotUser
  is_year_subscription: boolean
}

// Not used yet, here for future ref -> https://www.notion.so/angelstudios/Chatbot-Messages-a8c967c44f8d43caa4aafb02789948e6
export interface ChatbotMessageMerch extends ChatbotMessage {
  product: {
    name: string
    image: string
    url: string
  }
  type: ChatbotMessageType.merch
  user: ChatbotUser
  version: number
}

export interface ChatMessage {
  avatarUrl?: string | null
  isAdmin: boolean
  message: string
  name: string
  publicId: string
  userId: string
  hasPaid?: boolean
  hasInvested?: boolean
  isSubscribed?: boolean
  type: ChatbotMessageType
  metadata?: {
    isGuildMember?: boolean
    hasPaid?: boolean
    hasInvested?: boolean
    isSubscribed?: boolean
  }
}

export type ChatFeedMessage =
  | ChatMessage
  | ChatbotMessage
  | ChatbotMessagePif
  | ChatbotMessageMerch
  | ChatbotMessageClaim

export type ChatStatus = 'online' | 'offline'

export interface ChatSocketValues {
  context?: string
  chatStatus: ChatStatus
  connected: boolean
  connectingMessage: string
  error: unknown
  loading: boolean
  messages: ChatFeedMessage[]
  notConnectedMessage: string
  sendMessage: (text: string) => void
  timedOut: boolean
  totalViewers: number
  userId?: string
  userImage?: string
  projectSlug: string
}
