import React from 'react'
import { ChatbotMessage } from '@/molecules/ChatbotMessages/ChatbotMessage'
import { ChatFeedMessage } from '@/services/WatchPartyChatService'
import { TheatricalReleaseObject } from '@/types/codegen-federation'

interface ChatbotMessagesProps {
  messages: ChatFeedMessage[]
  isOneLineMessages: boolean
  className?: string
  initialMessageClassName?: string
  theatricalRelease?: TheatricalReleaseObject | null
}

export const ChatbotMessages: React.FC<ChatbotMessagesProps> = ({
  messages,
  className = '',
  isOneLineMessages,
  initialMessageClassName = '',
  theatricalRelease,
}) => {
  return (
    <>
      {messages.map((message) => {
        return (
          <ChatbotMessage
            initialMessageClassName={initialMessageClassName}
            className={className}
            key={message.publicId}
            message={message}
            isOneLineMessages={isOneLineMessages}
            theatricalRelease={theatricalRelease}
          />
        )
      })}
    </>
  )
}
