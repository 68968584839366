import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export function CalendarIcon({ className, size = 20, color = 'black' }: OneColorIconProps) {
  const fill = getHexColor(color)

  return (
    <svg className={className} width={size} height={size} viewBox="0 0 18 18" fill={fill}>
      <path d="M10.875 13.5a1.81 1.81 0 0 1-1.331-.544A1.81 1.81 0 0 1 9 11.625c0-.525.181-.969.544-1.331a1.81 1.81 0 0 1 1.331-.544c.525 0 .969.181 1.331.544.363.362.544.806.544 1.331 0 .525-.181.969-.544 1.331a1.81 1.81 0 0 1-1.331.544Zm-7.125 3c-.413 0-.766-.147-1.06-.44A1.446 1.446 0 0 1 2.25 15V4.5c0-.412.147-.765.44-1.059A1.445 1.445 0 0 1 3.75 3h.75v-.75c0-.212.072-.39.215-.535A.728.728 0 0 1 5.25 1.5c.213 0 .39.072.535.215A.728.728 0 0 1 6 2.25V3h6v-.75c0-.212.072-.39.216-.535a.725.725 0 0 1 .534-.215c.213 0 .39.072.534.215a.726.726 0 0 1 .216.535V3h.75c.412 0 .766.147 1.06.441.293.294.44.647.44 1.059V15c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H3.75Zm0-1.5h10.5V7.5H3.75V15Zm0-9h10.5V4.5H3.75V6Z" />
    </svg>
  )
}
