import React from 'react'
import { ChatNameDisplay } from '@/atoms/ChatNameDisplay'
import { CaptionMD } from '@/atoms/Text'
import { ChatMessage } from '@/services/WatchPartyChatService'
import { useTranslate } from '@/utils/translate/translate-client'
import { WatchPartyAvatar } from './WatchPartyAvatar'

export interface MessageProps {
  message: ChatMessage
  className?: string
}

export const WatchPartyMessage: React.FC<MessageProps> = React.memo(({ message }) => {
  const { t } = useTranslate('livestream')
  // this is temporary until all sources are updated to use metadata
  const isSubscribed = message.metadata?.isSubscribed || message.isSubscribed
  const hasInvested = message.metadata?.hasInvested || message.hasInvested
  const hasPaid = message.metadata?.hasPaid || message.hasPaid

  return (
    <div className="mb-2 flex flex-row items-center">
      <WatchPartyAvatar
        avatarUrl={message.avatarUrl}
        hasInvested={hasInvested}
        hasPaid={hasPaid}
        isSubscribed={isSubscribed}
        isGuildMember={message.metadata?.isGuildMember}
        name={message.name}
      />

      <CaptionMD face="roboto" className="pl-2 pr-4">
        <ChatNameDisplay className="block">{message.name?.trim() ?? t('anonymous', 'Anonymous')} </ChatNameDisplay>
        {message.message}
      </CaptionMD>
    </div>
  )
})

WatchPartyMessage.displayName = `React.memo(WatchPartyMessage)`
