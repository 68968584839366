import {
  ChatbotMessage,
  ChatbotMessageClaim,
  ChatbotMessageGuildSubscription,
  ChatbotMessageMerch,
  ChatbotMessagePif,
  ChatbotMessageTicketIntent,
  ChatbotMessageType,
  ChatFeedMessage,
  ChatMessage,
} from './types'

export const isChatMessage = (message: ChatFeedMessage): message is ChatMessage =>
  (message as ChatMessage).userId !== undefined && !(message as ChatMessage).isAdmin
export const isChatbotMessagePif = (message: ChatFeedMessage): message is ChatbotMessagePif =>
  (message as ChatbotMessage).type === ChatbotMessageType.pif
export const isChatbotMessageClaim = (message: ChatFeedMessage): message is ChatbotMessageClaim =>
  (message as ChatbotMessage).type === ChatbotMessageType.claim
export const isChatbotMessageTicketIntent = (message: ChatFeedMessage): message is ChatbotMessageTicketIntent =>
  (message as ChatbotMessage).type === ChatbotMessageType.ticket
export const isChatbotMessageMerch = (message: ChatbotMessage): message is ChatbotMessageMerch =>
  (message as ChatbotMessage).type === ChatbotMessageType.merch

export const isChatbotMessageGuildSubscription = (
  message: ChatFeedMessage,
): message is ChatbotMessageGuildSubscription => (message as ChatbotMessage).type === ChatbotMessageType.guild
