import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Case, Default, Switch } from 'react-if'
import { GuildSolidIcon } from '@/atoms/Icons/GuildSolidIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { CaptionMD, ParagraphSM } from '@/atoms/Text'
import { paths } from '@/constants'
import { useGuildUser } from '@/services/GuildUserService'
import { GET_THEATRICAL_RELEASES } from '@/services/Theatrical/queries'
import { GetTheatricalReleasesQuery, GetTheatricalReleasesQueryVariables } from '@/types/codegen-federation'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface GuildMessageProps {
  name: string
  isYearSubscription: boolean
}

type Prompt = 'early-access' | 'vote' | 'tickets'

export const GuildMessage: React.FC<GuildMessageProps> = React.memo(({ name, isYearSubscription }) => {
  const { t } = useTranslate('chatbot')
  const [prompt, setPrompt] = useState<Prompt>()
  const aFan = t('aFan', 'A fan')
  const userName = name ?? aFan
  const { isGuildMember } = useGuildUser()
  const { data } = useQuery<GetTheatricalReleasesQuery, GetTheatricalReleasesQueryVariables>(GET_THEATRICAL_RELEASES, {
    variables: { input: {} },
    errorPolicy: 'all',
  })

  const theatricalName = useMemo(() => {
    return data?.theatricalReleases?.theatricalRelease?.find((release) => release?.isFeaturedRelease)?.title
  }, [data?.theatricalReleases?.theatricalRelease])

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 3) + 1
    if (randomNumber === 1) setPrompt('vote')
    if (randomNumber === 2) setPrompt('early-access')
    if (randomNumber === 3) setPrompt('tickets')
  }, [])

  return (
    <div className="mb-2 flex flex-row items-center pl-1">
      <div
        className="flex shrink-0 items-center justify-center self-start overflow-hidden rounded-full bg-gray-800"
        style={{ height: `24px`, width: `24px` }}
      >
        <GuildSolidIcon size={14} color1="oxide-bright" color2="gray-950" />
      </div>

      <CaptionMD
        face="roboto"
        className={classNames('pl-2 pr-4 text-gray-300 overflow-x-hidden text-ellipsis font-[350]')}
      >
        {prompt !== undefined && (
          <Switch>
            <Case condition={isYearSubscription}>
              <ParagraphSM>
                <Switch>
                  <Case condition={prompt === 'early-access'}>
                    <Translate t={t} i18nKey="earlyAccessGuildYearV2" values={{ userName }}>
                      {{ userName }} joined the Angel Guild and now has early access to upcoming shows for a year.
                    </Translate>
                  </Case>
                  <Case condition={prompt === 'vote'}>
                    <Translate t={t} i18nKey="canVoteGuildYearV2" values={{ userName }}>
                      {{ userName }} joined the Angel Guild and can now vote on upcoming projects for a year.
                    </Translate>
                  </Case>
                  <Case condition={prompt === 'tickets' && theatricalName}>
                    <Translate t={t} i18nKey="guildTicketsMessageV2" values={{ userName, theatricalName }}>
                      {{ userName }} joined the Angel Guild and got 2 complimentary tickets to {{ theatricalName }}.
                    </Translate>
                  </Case>
                  <Default>
                    <Translate t={t} i18nKey="earlyAccessGuildYearV2" values={{ userName }}>
                      {{ userName }} joined the Angel Guild and now has early access to upcoming shows for a year.
                    </Translate>
                  </Default>
                </Switch>
              </ParagraphSM>
            </Case>
            <Case condition={!isYearSubscription}>
              <ParagraphSM>
                <Switch>
                  <Case condition={prompt === 'early-access'}>
                    <Translate t={t} i18nKey="earlyAccessGuildV2" values={{ userName }}>
                      {{ userName }} joined the Angel Guild and now has early access to upcoming shows.
                    </Translate>
                  </Case>
                  <Case condition={prompt === 'vote'}>
                    <Translate t={t} i18nKey="canVoteGuildV2" values={{ userName }}>
                      {{ userName }} joined the Angel Guild and can now vote on upcoming projects.
                    </Translate>
                  </Case>
                  <Case condition={prompt === 'tickets' && theatricalName}>
                    <Translate t={t} i18nKey="guildTicketsMessageV2" values={{ userName, theatricalName }}>
                      {{ userName }} joined the Angel Guild and got 2 complimentary tickets to {{ theatricalName }}.
                    </Translate>
                  </Case>
                  <Default>
                    <Translate t={t} i18nKey="earlyAccessGuildV2" values={{ userName }}>
                      {{ userName }} joined the Angel Guild and now has early access to upcoming shows.
                    </Translate>
                  </Default>
                </Switch>
              </ParagraphSM>
            </Case>
            <Default></Default>
          </Switch>
        )}
        {!isGuildMember && (
          <InternalLink
            href={`${paths.guild.index}?utm_campaign=guild-membership&utm_source=hypebot-${prompt}&utm_medium=web`}
            target="_blank"
            className="inline px-1 font-semibold text-oxide"
            rel="noreferrer"
          >
            <Translate i18nKey="joinTheGuildPeriod" t={t}>
              Join the Guild.
            </Translate>
          </InternalLink>
        )}
      </CaptionMD>
    </div>
  )
})

GuildMessage.displayName = 'React.memo(GuildMessage)'
