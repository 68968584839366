import React from 'react'
import classNames from 'classnames'
import { DateTime, Duration, DurationUnit, DurationUnits } from 'luxon'
import { SceneIcon } from '@/atoms/Icons/SceneIcon'
import { AsSpan, ParagraphSM } from '@/atoms/Text'
import { useClaimContext } from '@/organisms/ClaimCollectibleButton/ClaimContext'
import { ChatbotMessageClaim } from '@/services/WatchPartyChatService/types'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseChatbotMessage } from './BaseChatbotMessage'

interface ClaimDisplayMessageProps {
  message: ChatbotMessageClaim
  className?: string
}

interface SceneTextDisplayProps {
  children: React.ReactNode
  className: string
  onClick?: () => void
}

const SceneTextDisplay: React.FC<SceneTextDisplayProps> = ({ children, className, onClick }) => {
  return (
    <ParagraphSM
      as={AsSpan}
      weight="semibold"
      className={classNames('whitespace-nowrap inline', className, { 'cursor-pointer': !!onClick })}
      onClick={onClick}
    >
      {children}
    </ParagraphSM>
  )
}

const timeUnits = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'] as DurationUnit[]

export const ClaimMessage: React.FC<ClaimDisplayMessageProps> = React.memo(({ message }) => {
  const { t } = useTranslate('chatbot')
  const { locale } = useLocale()
  const { openModal } = useClaimContext()

  const userName = message.user?.first_name ?? t('anotherViewer', 'Another fan')

  const diff = DateTime.fromISO(message.eligible_at as string, { locale })
    .diffNow(timeUnits as DurationUnits, { conversionAccuracy: 'casual' })
    .negate()
    .toObject() as Record<DurationUnit, number>

  let unitHadAValue = false

  timeUnits.forEach((unit) => {
    if (unitHadAValue) {
      delete diff?.[unit]
    } else if (diff?.[unit] || 0 > 0) {
      unitHadAValue = true
    } else {
      delete diff?.[unit]
    }
  })

  const startedDiff = Duration.fromObject(diff)
  const diffDisplay = startedDiff.toHuman({ listStyle: 'short', maximumFractionDigits: 0 })

  let translatedComp = null
  if (message.eligible_action === 'investment') {
    translatedComp = (
      <>
        {userName} invested in the beginning. <wbr />
        Reserved a
        <span className="inline whitespace-nowrap">
          <SceneIcon className="mx-1 inline" size={20} color1="black" color2="collectible-blue" />
          <SceneTextDisplay className="text-collectible-blue" onClick={openModal}>
            Collectible Scene
          </SceneTextDisplay>
        </span>
      </>
    )
  } else if (message.eligible_action === 'pif' && message.offer_type === 'standard_scene') {
    translatedComp = (
      <>
        {userName} Paid it Forward for the first time {diffDisplay} ago. <wbr />
        <span className="inline whitespace-nowrap">
          Reserved a
          <SceneIcon className="mx-1 inline" size={20} color1="black" color2="collectible-blue" />
          <SceneTextDisplay className="text-collectible-blue" onClick={openModal}>
            Collectible Scene
          </SceneTextDisplay>
        </span>
      </>
    )
  } else if (message.eligible_action === 'pif_subscription' && message.offer_type === 'golden_scene') {
    translatedComp = (
      <>
        {userName} started Paying it Forward monthly {diffDisplay} ago. <wbr />
        <span className="inline whitespace-nowrap">
          Reserved a
          <SceneIcon size={20} className="mx-1 inline" color1="black" color2="collectible-gold" />
          <SceneTextDisplay className="text-collectible-gold" onClick={openModal}>
            Golden Scene
          </SceneTextDisplay>
        </span>
      </>
    )
  } else if (message.eligible_action === 'ticket_purchase' && message.offer_type === 'standard_scene') {
    translatedComp = (
      <>
        {userName} emailed their purchase confirmation to{' '}
        <a href="mailto:hisonlyson@angel.com" className="font-bold text-copper">
          hisonlyson@angel.com
        </a>
        . <wbr />
        <span className="inline whitespace-nowrap">
          Reserved a
          <SceneIcon className="mx-1 inline" size={20} color1="black" color2="collectible-blue" />
          <SceneTextDisplay className="text-collectible-blue">Collectible Scene</SceneTextDisplay>
        </span>
      </>
    )
  }

  if (!translatedComp) return null

  return <BaseChatbotMessage>{translatedComp}</BaseChatbotMessage>
})

ClaimMessage.displayName = 'React.memo(ClaimMessage)'
