import React from 'react'
import classNames from 'classnames'
import { AngelAIcon } from '@/atoms/Icons/AngelAIcon'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { CaptionMD } from '@/atoms/Text'

interface BaseChatbotMessage {
  children: React.ReactNode
  className?: string
  avatarSize?: number
  pif?: boolean
  angel?: boolean
}

export const BaseChatbotMessage: React.FC<BaseChatbotMessage> = React.memo(
  ({ children, className, avatarSize = 24, pif = false, angel = false }) => {
    return (
      <div className="mb-2 flex flex-row items-center pl-1">
        <div
          className={classNames(
            'self-start shrink-0 rounded-full justify-center items-center flex  overflow-hidden',
            angel ? 'bg-gray-100' : 'bg-gray-800',
          )}
          style={{ height: `${avatarSize}px`, width: `${avatarSize}px` }}
        >
          <div>
            {pif ? (
              <PayItForwardIcon size={avatarSize * 0.5} color="core-oxide" />
            ) : (
              <AngelAIcon size={avatarSize * 0.5} color={angel ? 'gray-800' : 'gray-100'} />
            )}
          </div>
        </div>

        <CaptionMD
          face="roboto"
          className={classNames(
            'pl-2 pr-4 overflow-x-hidden text-ellipsis',
            pif ? 'text-gray-400 font-[350]' : 'text-gray-500',
            className,
          )}
        >
          {children}
        </CaptionMD>
      </div>
    )
  },
)

BaseChatbotMessage.displayName = 'React.memo(BaseChatbotMessage)'
