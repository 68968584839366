import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Case, Default, Switch } from 'react-if'
import { Button } from '@/atoms/Button'
import { ChevronDownIcon } from '@/atoms/Icons/ChevronDownIcon'
import { ParagraphMD } from '@/atoms/Text'
import { ChatbotMessages } from '@/molecules/ChatbotMessages'
import { useWatchPartyChat } from '@/services/WatchPartyChatService'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'

interface AutoFollowChatMessageContainerProps {
  isOneLineMessages?: boolean
  hideScrollbar?: boolean
  isNewDesign?: boolean
  initialMessageClassName?: string
  theatricalRelease?: TheatricalReleaseObject | null
}

export const AutoFollowChatMessageContainer: ReactFCC<AutoFollowChatMessageContainerProps> = ({
  children,
  isOneLineMessages = false,
  hideScrollbar = false,
  isNewDesign = false,
  initialMessageClassName,
  theatricalRelease,
}) => {
  const scrollPositionRef = useRef<number>(0)
  const [isFollowing, setFollowing] = useState(true)
  const scrollableContainer = useRef<HTMLDivElement>(null)
  const { messages, connected, loading, connectingMessage, notConnectedMessage } = useWatchPartyChat()

  useEffect(() => {
    const element = scrollableContainer.current
    if (element) {
      if (isFollowing) {
        scrollPositionRef.current = element.scrollHeight
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
  }, [messages, isFollowing])

  const onScroll = () => {
    const chatContainer = scrollableContainer.current

    if (!chatContainer || hideScrollbar) {
      return
    }

    if (!isFollowing) {
      if (chatContainer.scrollTop + chatContainer.clientHeight >= scrollPositionRef.current) {
        setFollowing(true)
      }
    }
  }

  const stopFollowing = useCallback(() => {
    setFollowing(false)
  }, [setFollowing])

  const startFollowing = useCallback(() => {
    setFollowing(true)
  }, [setFollowing])

  const scrollableElement = scrollableContainer.current
  const isOverflown = scrollableElement ? scrollableElement.scrollHeight > scrollableElement.clientHeight : false
  const shouldShowFollowButton = Boolean(isOverflown && !isFollowing && !hideScrollbar)

  const stopFollowingCallbacks = !hideScrollbar
    ? {
        onClick: stopFollowing,
        onTouchMove: stopFollowing,
        onMouseDown: stopFollowing,
        onWheel: stopFollowing,
      }
    : {}

  return (
    <>
      <div
        className={classNames('relative mx-2 px-2 flex-stretch overflow-x-hidden h-full min-h-0', {
          'scrollbar scrollbar-thumb-gray-800 scrollbar-track-core-gray-950': !hideScrollbar,
          'overflow-y-hidden': hideScrollbar,
          'mr-1 !px-1 scrollbar-track-transparent scrollbar-thumb-gray-600': isNewDesign,
        })}
        id="chat_scroll"
        ref={scrollableContainer}
        onScroll={onScroll}
        {...stopFollowingCallbacks}
      >
        <div className="flex h-full flex-col">
          <Switch>
            <Case condition={connected}>
              <div className="pt-2">
                <ChatbotMessages
                  initialMessageClassName={initialMessageClassName}
                  messages={messages}
                  isOneLineMessages={isOneLineMessages}
                  theatricalRelease={theatricalRelease}
                />
              </div>
            </Case>
            <Case condition={loading}>
              <ParagraphMD className="pt-2">{connectingMessage}</ParagraphMD>
            </Case>
            <Default>
              <ParagraphMD className="pt-2">{notConnectedMessage}</ParagraphMD>
            </Default>
          </Switch>
        </div>
      </div>
      {shouldShowFollowButton && (
        <Button
          variant="gray-900"
          className="absolute bottom-[5%] left-1/2 flex h-[38px] w-[38px] -translate-x-1/2 items-center justify-center rounded-full p-3 drop-shadow-xl"
          onClick={startFollowing}
        >
          <ChevronDownIcon />
        </Button>
      )}
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as ReactElement, {
          startFollowing,
        })
      })}
    </>
  )
}
