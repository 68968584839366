import React, { useMemo } from 'react'
import { UserAchievements, useHydraUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'

type Badges = {
  isSubscribed: boolean
  hasInvested: boolean
  hasPaid: boolean
}

interface ProfileContextProps {
  children: React.ReactNode
}

interface ProfileContextType {
  achievements: UserAchievements[]
  badges: Badges
  profile?: {
    firstName: string
    lastName: string
    image: string
    city: string
    state: string
    country: string
  }
}

const ProfileContext = React.createContext<ProfileContextType>({
  achievements: [],
  badges: { hasPaid: false, isSubscribed: false, hasInvested: false },
  profile: undefined,
})

export const ProfileContextProvider: ReactFCC<ProfileContextProps> = ({ children }) => {
  const user = useHydraUser()

  const badges = useMemo<Badges>(() => {
    const result = {
      isSubscribed: false,
      hasInvested: false,
      hasPaid: false,
    }

    // TODO 5/5/23 this is the only place in the code base that uses `userAchievements`
    // The useHydraUser and other useUser type queries are one of the most common queries in the repo.
    // We should fetch userAchievements in a separate query.
    if (user.userAchievements) {
      user.userAchievements.forEach((achievement) => {
        switch (achievement.achievementType) {
          case 'PIF':
            result.hasPaid = true
            break
          case 'SUBSCRIBE':
            result.isSubscribed = true
            break
          case 'INVEST':
            result.hasInvested = true
            break
        }
      })
    }

    return result
  }, [user.userAchievements])

  const value = {
    achievements: user.userAchievements,
    badges,
    profile: user?.profile,
  }

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}

export function useProfileContext() {
  return React.useContext(ProfileContext)
}
