import React from 'react'
import { getHexColor, TwoColorIconProps } from '@/atoms/utils'

export const GuildSolidIcon: React.FC<TwoColorIconProps> = ({
  size = 20,
  color1 = 'gray-950',
  color2 = 'core-oxide-bright',
  className,
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.5 0H1.5C0.947715 0 0.5 0.445743 0.5 0.998027V8.88889C0.5 13.3333 4.0625 18.3333 10 20C15.9375 18.3333 19.5 13.3333 19.5 8.88889V0.997691C19.5 0.445406 19.0523 0 18.5 0Z"
        fill={c1}
      />
      <path
        d="M10.0095 8.18704H13.4562C13.5221 8.19219 13.588 8.22305 13.6184 8.27448C13.664 8.32077 13.6944 8.38763 13.6944 8.45964V12.9806C13.6944 13.0166 13.6843 13.0577 13.6691 13.0937C13.6539 13.1297 13.6286 13.1606 13.5981 13.1863C12.5439 13.7418 11.3679 14.0195 10.1768 13.9989C7.43967 13.9321 5.25 11.777 5.25 8.99968C5.25 6.22746 7.43967 3.96442 10.1768 4.00042C11.3324 4.03643 12.6097 4.55075 13.5475 5.41482C13.5779 5.4354 13.6032 5.46625 13.6184 5.49711C13.6387 5.52797 13.6488 5.56398 13.6488 5.59998C13.6488 5.63598 13.6438 5.67199 13.6286 5.70799C13.6133 5.73885 13.5931 5.76971 13.5677 5.79542L12.4982 6.93209C12.4425 6.96809 12.3766 6.98866 12.3107 6.98866C12.2448 6.98866 12.1789 6.96809 12.1231 6.93209C11.6011 6.41262 10.9878 6.22231 10.3238 6.20689C8.65616 6.17088 7.62215 7.46699 7.62215 8.98425C7.62215 10.5169 8.65616 11.7565 10.339 11.7925C10.7546 11.8028 11.1652 11.741 11.5605 11.6022V10.1929C11.2564 10.1775 10.2731 10.1775 9.99939 10.1775C9.96391 10.1775 9.93347 10.1723 9.89799 10.1621C9.86758 10.1466 9.83717 10.1312 9.81183 10.1055C9.78648 10.0798 9.76621 10.0489 9.751 10.018C9.73579 9.98719 9.73073 9.95118 9.73073 9.91518V8.44421C9.73073 8.4082 9.73579 8.3722 9.751 8.34134C9.76621 8.31048 9.78648 8.27962 9.81183 8.25391C9.83717 8.22819 9.86758 8.20762 9.90306 8.19733C9.93854 8.18704 9.97402 8.1819 10.0095 8.18704Z"
        fill={c2}
      />
    </svg>
  )
}
