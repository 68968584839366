import React from 'react'
import { When } from 'react-if'
import { paths } from '@/constants/paths'
import { slugs } from '@/constants/slugs'
import { useWatchPartyChat } from '@/services/WatchPartyChatService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { BaseChatbotMessage } from './BaseChatbotMessage'

interface PifMessageProps {
  message: string | React.ReactNode
  quantity?: number
  first_name?: string
  className?: string
}

export const PifMessage: React.FC<PifMessageProps> = React.memo(({ quantity, first_name }) => {
  const { context, projectSlug } = useWatchPartyChat()
  const { t } = useTranslate('chatbot')
  const aFan = t('aFan', 'A fan')
  const name = first_name ?? aFan

  const isIlluminate = context === 'illuminate-2023'
  const isTicketsPif = projectSlug === slugs.soundOfFreedom || isIlluminate

  return (
    <BaseChatbotMessage pif={true}>
      <When condition={isTicketsPif}>
        <Translate
          ns="livestream"
          i18nKey="userContributedToPIFToBuyTicketsForNUsers"
          count={quantity}
          values={{ name, quantity }}
        >
          <span className="font-semibold text-gray-100">{{ name }}</span> just contributed to
          <a
            href={`${paths.payItForward.index}/${slugs.soundOfFreedom}`}
            target="_blank"
            className="inline px-1 font-semibold text-oxide"
            rel="noreferrer"
          >
            Pay it Forward
          </a>
          to buy tickets for <span className="font-semibold text-gray-100">{{ quantity }} people</span>
        </Translate>
      </When>
      <When condition={!isTicketsPif}>
        <Translate ns="livestream" i18nKey="userPaiditForwardForNUsers" count={quantity} values={{ name }}>
          <span className="font-semibold text-gray-100">{{ name }}</span> just
          <a
            href={`${paths.payItForward.index}/${projectSlug}`}
            target="_blank"
            className="inline px-1 font-semibold text-oxide"
            rel="noreferrer"
          >
            Paid it Forward
          </a>
          for <span className="font-semibold text-gray-100">{{ quantity }} people</span>
        </Translate>
      </When>
    </BaseChatbotMessage>
  )
})

PifMessage.displayName = 'React.memo(PifMessage)'
