import React from 'react'
import classNames from 'classnames'
import { getHexColor, IconColor, TwoColorIconProps } from '../utils'

export interface FirstInitialProfileIconProps extends TwoColorIconProps {
  initial?: string
}

export const FirstInitialProfileIcon: React.FC<FirstInitialProfileIconProps> = ({
  className,
  initial = 'A',
  size = 24,
  color1 = '#c73358',
  color2 = 'white',
}) => {
  const displayedInitial = initial?.trim().length > 0 ? initial?.charAt(0) : 'A'

  return (
    <div
      aria-label="user-initial"
      data-testid="nft-transaction-user-icon"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        borderRadius: `${size / 2}px`,
        backgroundColor: getHexColor(color1 as IconColor),
        color: getHexColor(color2),
      }}
      className={classNames('align-middle text-center text-md font-semibold', className)}
    >
      {displayedInitial.toUpperCase()}
    </div>
  )
}
