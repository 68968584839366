import React, { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { useUser } from '@/services/UserService'
import { useUserFinancials } from '@/services/UserService'

type ClaimModalState = 'instructions' | 'confirmation' | 'noClaims'

interface ClaimContextState {
  isOpen: boolean
  closeModal: () => void
  openModal: () => void
  claimModalState: ClaimModalState
  setClaimModalState: (state: ClaimModalState) => void
  isAwesome: boolean
  userStatus: {
    isChosenInvestor?: boolean | null
    isChosenPiffer?: boolean | null
    isChosenSubscriber?: boolean | null
    isChosenSubscriberSceneClaimed?: boolean | null
    isChosenPifferSceneClaimed?: boolean | null
    isChosenInvestorSceneClaimed?: boolean | null
  }
  refetch: () => Promise<ApolloQueryResult<unknown> | undefined>
  userId?: string | null
  anyClaims: boolean
}

const ClaimContext = React.createContext<ClaimContextState>({
  isOpen: false,
  openModal: () => null,
  closeModal: () => null,
  claimModalState: 'instructions',
  setClaimModalState: () => null,
  isAwesome: false,
  userStatus: {
    isChosenInvestor: false,
    isChosenPiffer: false,
    isChosenSubscriber: false,
    isChosenSubscriberSceneClaimed: false,
    isChosenPifferSceneClaimed: false,
    isChosenInvestorSceneClaimed: false,
  },
  refetch: () => Promise.resolve(undefined),
  anyClaims: false,
})

interface ClaimContextProps {
  children: React.ReactNode
}

export const ClaimContextProvider: React.FC<ClaimContextProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const { user, refetch } = useUser()
  const { userFinancials } = useUserFinancials()
  const { isChosenInvestor, isChosenSubscriber, isChosenPiffer } = userFinancials?.profile ?? {}

  const { isChosenInvestorSceneClaimed, isChosenPifferSceneClaimed, isChosenSubscriberSceneClaimed } =
    user?.profile ?? {}
  const userId = user?.uuid
  const isAwesome = Boolean(isChosenInvestor || isChosenSubscriber || isChosenPiffer)
  const anyClaims = Boolean(
    isChosenPifferSceneClaimed || isChosenInvestorSceneClaimed || isChosenSubscriberSceneClaimed,
  )
  const [claimModalState, setClaimModalState] = useState<ClaimModalState>(
    anyClaims ? 'confirmation' : !userId ? 'instructions' : 'noClaims',
  )

  const closeModal = () => {
    setOpen(false)
  }

  const userStatus = {
    isChosenInvestor,
    isChosenPiffer,
    isChosenSubscriber,
    isChosenSubscriberSceneClaimed,
    isChosenPifferSceneClaimed,
    isChosenInvestorSceneClaimed,
  }

  const openModal = async () => {
    const mightHaveUnclaimedScenes = anyClaims && (!isChosenSubscriberSceneClaimed || !isChosenPifferSceneClaimed)

    if (!!userId && mightHaveUnclaimedScenes) {
      return refetch().finally(() => {
        if (claimModalState !== 'confirmation') {
          setClaimModalState(anyClaims ? 'confirmation' : 'instructions')
        }
        setOpen(true)
      })
    } else if (claimModalState !== 'confirmation') {
      setClaimModalState(anyClaims ? 'confirmation' : 'instructions')
    }

    setOpen(true)
  }

  const values = {
    userId,
    anyClaims,
    isOpen,
    closeModal,
    openModal,
    claimModalState,
    setClaimModalState,
    isAwesome,
    userStatus,
    refetch,
  }

  return <ClaimContext.Provider value={values}>{children}</ClaimContext.Provider>
}

export function useClaimContext() {
  return React.useContext(ClaimContext)
}
