import React from 'react'
import classNames from 'classnames'
import { ChatNameDisplay } from '@/atoms/ChatNameDisplay'
import { BaseChatbotMessage } from './BaseChatbotMessage'

interface AppMessageProps {
  message: string | React.ReactNode
  className?: string
  displayNameClassName?: string
  avatarSize?: number
}

export const AppMessage: React.FC<AppMessageProps> = React.memo(
  ({ message, className, displayNameClassName, avatarSize = 48 }) => {
    return (
      <BaseChatbotMessage avatarSize={avatarSize} className={className} angel={true}>
        <>
          <ChatNameDisplay className={classNames('text-gray-100 mr-1', displayNameClassName)}>
            Angel Studios
          </ChatNameDisplay>
          <span className="font-[350] text-gray-400">{message}</span>
        </>
      </BaseChatbotMessage>
    )
  },
)

AppMessage.displayName = 'React.memo(AppMessage)'
