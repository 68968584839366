import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const FireIcon: React.FC<OneColorIconProps> = ({ size = 8, className, color = 'white' }) => {
  const c = getHexColor(color)
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.666504 4.48854C0.666504 3.70382 0.899143 2.95035 1.36442 2.22813C1.8297 1.50591 2.46859 0.873961 3.28109 0.332295C3.43386 0.228128 3.59192 0.22285 3.75525 0.316461C3.91831 0.41035 3.99984 0.551045 3.99984 0.738545V1.28021C3.99984 1.51632 4.0815 1.71424 4.24484 1.87396C4.40789 2.03368 4.60748 2.11354 4.84359 2.11354C4.96164 2.11354 5.07456 2.08757 5.18234 2.03563C5.28984 1.98341 5.38525 1.90868 5.46859 1.81146C5.52414 1.74202 5.59539 1.69854 5.68234 1.68104C5.769 1.66382 5.85053 1.68299 5.92692 1.73854C6.36442 2.05104 6.70817 2.45035 6.95817 2.93646C7.20817 3.42257 7.33317 3.93993 7.33317 4.48854C7.33317 5.09966 7.18386 5.65688 6.88525 6.16021C6.58664 6.66382 6.19428 7.06146 5.70817 7.35313C5.82623 7.18646 5.91831 7.0041 5.98442 6.80604C6.05025 6.60827 6.08317 6.39827 6.08317 6.17604C6.08317 5.89827 6.03109 5.63618 5.92692 5.38979C5.82275 5.14313 5.67345 4.92257 5.479 4.72813L3.99984 3.28021L2.53109 4.72813C2.3297 4.92952 2.17692 5.15174 2.07275 5.39479C1.96859 5.63785 1.9165 5.89827 1.9165 6.17604C1.9165 6.39827 1.94956 6.60827 2.01567 6.80604C2.0815 7.0041 2.17345 7.18646 2.2915 7.35313C1.80539 7.06146 1.41303 6.66382 1.11442 6.16021C0.815809 5.65688 0.666504 5.09966 0.666504 4.48854ZM3.99984 4.44688L4.88525 5.31146C5.00331 5.42952 5.09359 5.56146 5.15609 5.70729C5.21859 5.85313 5.24984 6.00938 5.24984 6.17604C5.24984 6.51632 5.12831 6.80618 4.88525 7.04563C4.6422 7.28535 4.34706 7.40521 3.99984 7.40521C3.65262 7.40521 3.35748 7.28535 3.11442 7.04563C2.87137 6.80618 2.74984 6.51632 2.74984 6.17604C2.74984 6.01632 2.78109 5.86188 2.84359 5.71271C2.90609 5.56327 2.99637 5.42952 3.11442 5.31146L3.99984 4.44688Z"
        fill={c}
      />
    </svg>
  )
}
