import React from 'react'
import classNames from 'classnames'
import { CaptionMD } from '@/atoms/Text'

interface ChatNameDisplayProps {
  children: React.ReactNode
  className?: string
}

export const ChatNameDisplay: React.FC<ChatNameDisplayProps> = ({ children, className }) => {
  return (
    <CaptionMD as={(props) => <span {...props} />} weight="semibold" className={classNames('text-gray-600', className)}>
      {children}
    </CaptionMD>
  )
}
