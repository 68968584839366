import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const AngelAIcon: React.FC<OneColorIconProps> = ({ className, color = 'white', size = 32 }) => {
  const hex = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_703_3708)">
        <path
          d="M16.616 64.8579C16.7616 64.8048 16.895 64.723 17.0084 64.6173C17.1218 64.5117 17.2128 64.3844 17.276 64.2429L32.369 30.7279L32.594 30.4389L32.82 30.7279L39.761 46.6859L29.835 48.6859C28.53 48.9499 28.612 50.3999 29.901 51.0359L45.93 59.1359C46.422 59.392 46.9782 59.4984 47.53 59.4419L58.297 58.0709C59.197 57.8769 60.156 57.3149 60.173 56.3859C60.1811 56.0749 60.1149 55.7663 59.98 55.4859L34.779 1.12493C34.1716 0.557472 33.4132 0.17758 32.595 0.0309381C32.1691 0.0113734 31.7453 0.101733 31.3643 0.293328C30.9834 0.484924 30.6582 0.771303 30.42 1.12493L0.188993 66.2049C0.0630329 66.4737 -0.0014929 66.7671 2.84813e-05 67.0639C-0.00315374 67.3044 0.0441432 67.5429 0.138883 67.7639C0.233624 67.9849 0.37368 68.1836 0.550016 68.3471C0.726353 68.5107 0.93502 68.6354 1.16257 68.7132C1.39011 68.791 1.63146 68.8202 1.871 68.7989C2.343 68.7979 16.616 64.8579 16.616 64.8579Z"
          fill={hex}
        />
      </g>
      <defs>
        <clipPath id="clip0_703_3708">
          <rect width={60} height={69} fill={hex} />
        </clipPath>
      </defs>
    </svg>
  )
}
