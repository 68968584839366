import React, { useMemo } from 'react'
import classNames from 'classnames'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { CaptionMD } from '@/atoms/Text'
import { TheatricalRegions } from '@/contexts/TheatricalRegionContext/TheatricalRegionTypes'
import { ChatbotMessageTicketIntent } from '@/services/WatchPartyChatService/types'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface ClaimDisplayMessageProps {
  message: ChatbotMessageTicketIntent
  className?: string
  theatricalRelease?: TheatricalReleaseObject | null
}

export const TicketIntentMessage: React.FC<ClaimDisplayMessageProps> = React.memo(
  ({ message, className, theatricalRelease }) => {
    const { t } = useTranslate('chatbot')
    const regionCode = theatricalRelease?.region?.countryCode ?? 'US'
    const { locale } = useLocale()

    const getUserName = (firstName?: string) => {
      if (!firstName || firstName === 'Anonymous') {
        return t('aFan', 'A fan')
      } else {
        return firstName
      }
    }

    const countryName = useMemo(() => {
      try {
        const regionNames = new Intl.DisplayNames([locale ?? 'en'], { type: 'region' })
        return regionNames.of(message.venue_country_code)
      } catch (err) {
        return 'United States'
      }
    }, [locale, message.venue_country_code])

    const userName = getUserName(message.user?.first_name)
    const theaterName = message.venue_name
    const location = useMemo(() => {
      const { venue_city, venue_country_code, venue_state } = message
      const isUS = regionCode === 'US' && venue_country_code === 'US'

      if (!countryName) return t('theUnitedStates', 'The United States')
      if (venue_city === 'Dummy City') return countryName
      if (!isUS && venue_city) return `${venue_city}, ${countryName}`
      if (venue_state) return `${venue_state}, ${countryName}`

      return t('theUnitedStates', 'The United States')
    }, [countryName, message, regionCode, t])

    const shouldShowStateFlag = useMemo(() => {
      const liveRegions = theatricalRelease?.theatricalReleaseRegions?.filter(
        (region) => region?.ticketsStatus === 'PRE_SALES' || region?.ticketsStatus === 'LIVE',
      )
      return liveRegions?.length === 1 && liveRegions?.[0]?.countryCode === 'US'
    }, [theatricalRelease?.theatricalReleaseRegions])

    const flag = useMemo(() => {
      if (shouldShowStateFlag && message?.venue_state && message.venue_country_code !== TheatricalRegions.CA) {
        return `us-${message.venue_state.toLowerCase()}`
      }
      return message?.venue_country_code?.toLowerCase()
    }, [message.venue_country_code, message.venue_state, shouldShowStateFlag])

    return (
      <div className="mb-2 flex flex-row items-center pl-1">
        <div
          className="flex shrink-0 items-center justify-center self-start overflow-hidden rounded-full bg-gray-800"
          style={{ height: `24px`, width: `24px` }}
        >
          <Image
            alt="country"
            src={`${flag ?? 'us'}.webp`}
            width={16}
            height={12}
            quality={100}
            loader={({ src }) => `https://flagcdn.com/32x24/${src}`}
          />
        </div>

        <CaptionMD
          face="roboto"
          className={classNames('pl-2 pr-4 text-gray-300 overflow-x-hidden text-ellipsis font-[350]', className)}
        >
          <Translate t={t} i18nKey="aFanWentToBuyTickets" values={{ userName, theaterName, location }}>
            {{ userName }} is buying tickets at <span className="font-semibold text-gray-100">{{ theaterName }}</span>{' '}
            in <span className="font-semibold text-gray-100">{{ location }}</span>
          </Translate>
        </CaptionMD>
      </div>
    )
  },
)

TicketIntentMessage.displayName = 'React.memo(TicketIntentMessage)'
